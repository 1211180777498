<template>
  <!--  面包屑导航 -->
  <div class="getSubject">
    <div>
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item>
          <el-input
            placeholder="查询机构名称"
            v-model="blurKey"
            @keyup.enter.native="onSubmit()"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit" @keyup.enter="onSubmit()"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="piliangcha">新增会员</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-card>
        <div class="addhui">
          <div>添加合作机构结果</div>
          <div class="second-addhui">
            <div
              @click="sort()"
              class="first"
              :class="status ? 'addcolor' : ''"
            >
              正式会员
            </div>
            <div
              @click="sortd()"
              class="second"
              :class="!status ? 'addcolor' : ''"
            >
              试用会员
            </div>
          </div>
        </div>
        <div id="table1">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            :empty-text="emptyText"
            style="width: 100%"
            v-show="status"
            :header-cell-style="{ background: '#BFDFF7', color: '#606266' }"
          >
            <el-table-column label="序号" type="index"></el-table-column>
            <el-table-column
              align="center"
              prop="orgType"
              label="类型"
            ></el-table-column>
            <el-table-column align="center" prop="orgName" label="公司名称">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="conpany(scope.row)"
                  size="mini"
                  >{{ scope.row.orgName }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="memberLevel"
              label="会员等级"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="userName"
              label="管理员名称"
            ></el-table-column>
            <!-- 滑块 -->
            <el-table-column align="center" prop="" label="管理">
              <template slot-scope="scope">
                <el-switch
                  :active-value="true"
                  :inactive-value="false"
                  v-model="scope.row.status"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="statusRight(scope.row)"
                ></el-switch>
              </template>
            </el-table-column>
            <!-- 、、 -->
            <el-table-column
              align="center"
              prop="endTime"
              label="到期时间"
            ></el-table-column>
            <el-table-column align="center" label="删除">
              <template slot-scope="scope">
                <!-- {{scope.row}} -->
                <el-button @click="deleteListOne(scope.row)">删除</el-button>
              </template>
            </el-table-column>

            <!-- <el-table-column align="center" prop="endTime" label="权限">

             <el-button type="text" >点击打开 Dialog</el-button>
           </el-table-column> -->
            <el-table-column align="center" label="操作" width="130px">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  @click="dialogVisibleqx = true"
                  icon="el-icon-edit"
                  size="mini"
                  >会员权限</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页器开始 -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="someOne.pagenum"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="someOne.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            rel="table1"
            v-show="status"
          ></el-pagination>
          <el-dialog :visible.sync="dialogVisibleqx" width="50%">
            <div>
              <div class="quanxian">权限编辑</div>
              <el-card class="dengji">
                <div class="text">登记状态</div>
                <el-switch
                  v-model="authority.register.registerQuery"
                  inactive-text="登记查询"
                ></el-switch>
                <el-switch
                  class="dengjiSecond"
                  v-model="authority.register.registerReport"
                  inactive-text="登记上报"
                ></el-switch>
              </el-card>
              <div class="zhuti">
                <el-card class="dengji">
                  <div class="text">主体信息</div>
                  <el-switch
                    v-model="authority.subject.subjectQuery"
                    inactive-text="主体信息查询"
                  ></el-switch>
                  <el-switch
                    class="dengjiSecond"
                    v-model="authority.subject.subjectReport"
                    inactive-text="主体信息上报"
                  ></el-switch>
                </el-card>
              </div>
              <div class="zhuti">
                <el-card class="dengji">
                  <div class="text">关注管理</div>
                  <el-switch
                    v-model="authority.focus.focusMain"
                    inactive-text="关注主体信息"
                  ></el-switch>
                  <el-switch
                    class="dengjiSecond"
                    v-model="authority.focus.focusCar"
                    inactive-text="关注车辆信息"
                  ></el-switch>
                </el-card>
              </div>
              <div class="zhuti">
                <el-card class="dengji">
                  <div class="text">行业主体</div>
                  <el-switch
                    v-model="authority.industry.industryReport"
                    inactive-text="行业主体上报"
                  ></el-switch>
                </el-card>
              </div>
              <div class="zhuti">
                <el-card class="dengji">
                  <div class="text">车辆车价查询</div>
                  <el-switch
                    v-model="authority.carPrice.carPriceQuery"
                    inactive-text="车型查询"
                  ></el-switch>
                </el-card>
              </div>
              <div class="zhuti">
                <el-card class="dengji">
                  <div class="text">账号管理</div>
                  <el-switch
                    v-model="authority.judge.jurisdiction"
                    inactive-text="账号管理"
                  ></el-switch>
                </el-card>
              </div>
            </div>
            <el-button class="tiSure" type="primary">提交确认</el-button>
          </el-dialog>
          <!-- 分页器结束 -->
        </div>
        <div id="table2">
          <el-table
            ref="multipleTable"
            :data="tableDA"
            tooltip-effect="dark"
            :empty-text="emptyText"
            style="width: 100%"
            v-show="!status"
            :header-cell-style="{ background: '#BFDFF7', color: '#606266' }"
          >
            <el-table-column
              align="center"
              label="序号"
              type="index"
            ></el-table-column>

            <el-table-column
              align="center"
              prop="orgType"
              label="机构名称"
            ></el-table-column>
            <el-table-column align="center" label="公司名称">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="conpany(scope.row)"
                  size="mini"
                  >{{ scope.row.orgName }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="memberLevel"
              label="会员等级"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="userName"
              label="管理员名称"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="endTime"
              label="到期时间"
            ></el-table-column>
            <el-table-column align="center" prop="name" label="正式会员">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  @click="indexedit(scope.row)"
                  icon="el-icon-edit"
                  size="mini"
                  >升级正式会员</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChanged"
            @current-change="handleCurrentChanged"
            :current-page="someOned.pagenumd"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="someOned.pagesized"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totald"
            v-show="!status"
            rel="table2"
          ></el-pagination>
        </div>
      </el-card>
    </div>

    <el-dialog
      title="会员开通"
      :visible.sync="dialogVisible"
      width="50%"
      center
    >
      <!-- 选项卡 -->
      <!-- 个人企业 -->
      <el-tabs
        v-model="activeName"
        @tab-click="handleClick"
        type="card"
        class="homeMain"
      >
        <!-- 个人 -->
        <el-tab-pane label="个人" name="first" class="deleteBorder">
          <div class="dia">
            <div class="a_diaa_dia">
              <div class="first_dia">类型</div>
              <el-select
                v-model="addForm.orgType"
                class="data_first_dia"
                placeholder="请选择"
              >
                <!-- <el-select v-model="value" placeholder="请选择"> -->
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <!-- 姓名 -->
            <div class="a_diaa_dia">
              <div class="first_dia">姓名</div>
              <input
                type="text"
                class="second_dia"
                v-show="isShow"
                 @blur.capture="onChangeName"
                v-model="addForm.orgName"
                placeholder="请输入姓名"
              />
              <input
                type="text"
                class="second_dia"
                v-show="!isShow"
                v-model="addForm.orgName"
                readonly="readonly"
                placeholder="请输入姓名"
              />
            </div>
            <!-- 身份证号 -->
            <div class="a_diaa_dia">
              <div class="first_dia">身份证号</div>
              <input
                type="text"
                class="second_dia"
                maxlength="18"
                v-show="isShow"
                v-model="addForm.idCard"
                placeholder="请输入身份证号"
              />
              <input
                type="text"
                class="second_dia"
                maxlength="18"
                v-show="!isShow"
                readonly="readonly"
                v-model="addForm.idCard"
                placeholder="请输入身份证号"
              />
            </div>
            <!-- 管理账号 -->
            <div class="a_diaa_dia">
              <div class="first_dia">管理账号</div>
              <input
                type="text"
                class="second_dia"
                v-model="addForm.loginName"
                placeholder="请输入管理账号"
              />
            </div>
            <!-- 金币充值 -->
            <div class="a_diaa_dia">
              <div class="first_dia">充值金币</div>
              <input
                type="text"
                class="second_dia"
                v-model="addForm.coin"
                placeholder="请输入金币额度"
              />
            </div>
            <!-- 金额充值 -->
            <div class="a_diaa_dia">
              <div class="first_dia">充值金额</div>
              <input
              v-model="addForm.money"
                type="text"
                class="second_dia"
                placeholder="请输入充值金额"
              />
            </div>
            <!-- 手机号 -->
            <div class="a_diaa_dia">
              <div class="first_dia">手机号</div>
              <input
                type="text"
                class="second_dia"
                v-show="isShow"
                @blur.capture="onChange"
                v-model="addForm.phone"
                placeholder="请输入手机号"
              />
              <input
                type="text"
                class="second_dia"
                v-show="!isShow"
                v-model="addForm.phone"
                placeholder="请输入手机号"
              />
            </div>
            <!-- 合作时间 -->
            <div class="a_diaa_dia">
              <div class="first_dia">合作时间</div>
              <el-date-picker
                v-model="addForm.time"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </div>
            <div class="a_diaa_dia">
              <div class="first_dia">会员等级</div>
              <el-select
                v-model="addForm.memberLevel"
                class="data_first_dia"
                @change="memberLevelchang"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>

            <div>
              <div class="chanpin">产品展示</div>
              <el-table :data="gridData">
                <el-table-column
                  align="center"
                  property="product"
                  label="项目"
                  width="150"
                ></el-table-column>
                <el-table-column
                  align="center"
                  property="countTotal"
                  label="次数"
                  width="200"
                ></el-table-column>
                <el-table-column
                  align="center"
                  property="countMonth"
                  label="最大次数/月"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.countMonth"
                      @input="getNum"
                      class="group-inp"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  property="countYear"
                  label="最大次数/年"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.countYear"
                      @input="getNum"
                      class="group-inp"
                    ></el-input>
                  </template>
                </el-table-column>
              </el-table>
              <div class="ender">
                  <div class="endera" @click="Photoaddd">合同上传</div>
                <div class="endera" @click="Photoadd">支付凭证上传</div>
              <div class="endera" @click="Photoadddd">身份证正面</div>
                <div class="endera" @click="Photoaddddd">身份证背面</div>
                <!-- <div class="endera" @click="onshen">身份证正面</div>
                <div class="endera" @click="onshenbei">身份证背面</div> -->
                <!-- <div class="endera" @click="Photoad">营业执照上传</div> -->
              </div>
            </div>
            <div class="tijiao" @click="tijiao">提交</div>
          </div>
        </el-tab-pane>
        <!-- 企业 -->
        <el-tab-pane label="企业" name="second">
          <div class="dia">
            <div class="a_diaa_dia">
              <div class="first_dia">类型</div>
              <el-select
                v-model="addForm.orgType"
                class="data_first_dia"
                placeholder="请选择"
              >
                <!-- <el-select v-model="value" placeholder="请选择"> -->
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <!-- 公司名称 -->
            <div class="a_diaa_dia">
              <div class="first_dia">公司名称</div>
              <input
                type="text"
                class="second_dia"
                v-show="isShow"
                @blur.capture="onChangeName"
                v-model="addForm.orgName"
                placeholder="请输入公司名称"
              />
              <input
                type="text"
                class="second_dia"
                v-show="!isShow"
                v-model="addForm.orgName"
                readonly="readonly"
                placeholder="请输入公司名称"
              />
            </div>
            <!-- 信用代码 -->
            <div class="a_diaa_dia">
              <div class="first_dia">统一社会信用代码</div>
              <input
                type="text"
                class="second_dia"
                maxlength="18"
                v-show="isShow"
                v-model="addForm.uscCode"
                placeholder="请输入信用代码"
              />
              <input
                type="text"
                class="second_dia"
                maxlength="18"
                v-show="!isShow"
                readonly="readonly"
                v-model="addForm.uscCode"
                placeholder="请输入信用代码"
              />
            </div>
            <!-- 管理账号 -->
            <div class="a_diaa_dia">
              <div class="first_dia">管理账号</div>
              <input
                type="text"
                class="second_dia"
                v-model="addForm.loginName"
                placeholder="请输入管理账号"
              />
            </div>
            <div class="a_diaa_dia">
              <div class="first_dia">手机号</div>
              <input
                type="text"
                class="second_dia"
                v-show="isShow"
                @blur.capture="onChange"
                v-model="addForm.phone"
                placeholder="请输入手机号"
              />
              <input
                type="text"
                class="second_dia"
                v-show="!isShow"
                v-model="addForm.phone"
                placeholder="请输入手机号"
              />
            </div>
            <!-- 金币充值 -->
            <div class="a_diaa_dia">
              <div class="first_dia">充值金币</div>
              <input
                type="text"
                class="second_dia"
                v-model="addForm.coin"
                placeholder="请输入金币额度"
              />
            </div>
            <!-- 金额充值 -->
            <div class="a_diaa_dia">
              <div class="first_dia">充值金额</div>
              <input
                type="text"
                class="second_dia"
                v-model="addForm.money"
                placeholder="请输入充值金额"
              />
            </div>

            <!-- 合作时间 -->
            <div class="a_diaa_dia">
              <div class="first_dia">合作时间</div>
              <el-date-picker
                v-model="addForm.time"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </div>
            <div class="a_diaa_dia">
              <div class="first_dia">会员等级</div>
              <el-select
                v-model="addForm.memberLevel"
                class="data_first_dia"
                @change="memberLevelchang"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>

            <div>
              <div class="chanpin">产品展示</div>
              <el-table :data="gridData">
                <el-table-column
                  align="center"
                  property="product"
                  label="项目"
                  width="150"
                ></el-table-column>
                <el-table-column
                  align="center"
                  property="countTotal"
                  label="次数"
                  width="200"
                ></el-table-column>
                <el-table-column
                  align="center"
                  property="countMonth"
                  label="最大次数/月"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.countMonth"
                      @input="getNum"
                      class="group-inp"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  property="countYear"
                  label="最大次数/年"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.countYear"
                      @input="getNum"
                      class="group-inp"
                    ></el-input>
                  </template>
                </el-table-column>
              </el-table>
              <div class="ender">
                <div class="endera" @click="Photoaddd">合同上传</div>
                <div class="endera" @click="Photoadd">支付凭证上传</div>
                <div class="endera" @click="Photoad">营业执照上传</div>
              </div>
            </div>
            <div class="tijiao" @click="tijiao">提交</div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <!-- 表格上传并查询代码 -->

    <!-- 公司信息弹窗的开始 -->
    <el-dialog
      title="公司信息"
      :visible.sync="companyDialogVisible"
      width="50%"
      center
    >
      <el-row>
        <el-col :span="12">
          <div class="compangdia">
            <div class="first_compangdia">类型</div>
            <div class="second_compangdia">{{ userlist.orgType }}</div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="compangdia">
            <div class="first_compangdia">会员等级</div>
            <div class="second_compangdia">{{ userlist.memberLevel }}</div>
            <div class="three_compangdia" @click="edit">会员编辑</div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <div class="compangdia">
            <div class="first_compangdia">公司名称</div>
            <div class="second_compangdia">{{ userlist.orgName }}</div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="compangdia">
            <div class="first_compangdia">管理员账号</div>
            <div class="second_compangdia">{{ userlist.userName }}</div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <div class="compangdia">
            <div class="first_compangdia">开通时间</div>
            <div class="second_compangdia">{{ userlist.startTime }}</div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="compangdia">
            <div class="first_compangdia">到期时间</div>
            <div class="second_compangdia">{{ userlist.endTime }}</div>
          </div>
        </el-col>
      </el-row>
      <div class="chanpin">产品展示</div>
      <el-table :data="gridData">
        <el-table-column
          align="center"
          property="product"
          label="项目"
          width="150"
        ></el-table-column>
        <el-table-column
          align="center"
          property="countTotal"
          label="次数"
          width="200"
        ></el-table-column>
        <el-table-column
          align="center"
          property="countMonth"
          label="最大次数/月"
        ></el-table-column>
        <el-table-column
          align="center"
          property="countYear"
          label="最大次数/年"
        ></el-table-column>
      </el-table>
      <div class="ender">
        <div class="endera" @click="editd">合同</div>
        <div class="endera" @click="editdd">营业执照</div>
      </div>
    </el-dialog>
    <!-- 公司信息弹窗的结束 -->
    <!-- 合格证上传 -->
    <el-dialog title="添加图片" :visible.sync="phoyodialogVisible" width="30%">
      <div class="biaoge">
        <el-upload
          drag
          :multiple="true"
          :limit="3"
          :auto-upload="false"
          accept=".jpg, .jpeg, .png, .JPG, .JPEG, .PNG"
          :action="UploadUrl()"
          :on-change="fileChange"
          :on-exceed="exceedFile"
          :on-success="handleSuccess"
          :on-error="handleError"
          :file-list="fileList"
          :before-remove="beforeRemove"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将图片拖到此处，或
            <em>点击上传</em>
          </div>
          <div class="el-upload__tip" slot="tip">只能上传图片</div>
        </el-upload>
        <br />
        <el-button size="small" type="primary" @click="uploadFile"
          >立即上传</el-button
        >
        <el-button size="small" @click="quadd">取消</el-button>
      </div>
    </el-dialog>
    <!-- 图片预览 -->
    <el-dialog
      title="图片预览"
      :visible.sync="edutDialogVisible"
      width="50%"
      center
    >
      <viewer :images="photo">
        <img
          v-for="(src, index) in photo"
          :src="src"
          width="30%"
          height="30%"
          :key="index"
        />
      </viewer>
    </el-dialog>
    <el-dialog
      title="图片预览"
      :visible.sync="tDialogVisible"
      width="50%"
      center
    >
      <viewer :images="photod">
        <img
          v-for="(src, index) in photod"
          :src="src"
          width="30%"
          height="30%"
          :key="index"
        />
      </viewer>
    </el-dialog>
    <!-- 身份证 -->
        <el-dialog title="添加身份证正面" :visible.sync="shenfenzheng" width="30%">
      <div class="biaoge">
        <el-upload
          drag
          :multiple="true"
          :limit="1"
          :auto-upload="false"
          accept=".jpg, .jpeg, .png, .JPG, .JPEG, .PNG"
          :action="UploadUrl()"
          :on-change="fileChangeshen"
          :on-exceed="exceedFile"
          :on-success="handleSuccess"
          :on-error="handleError"
          :file-list="fileListshen"
          :before-remove="beforeRemoveshen"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将图片拖到此处，或
            <em>点击上传</em>
          </div>
          <div class="el-upload__tip" slot="tip">只能上传图片</div>
        </el-upload>
        <br />
        <el-button size="small" type="primary" @click="uploadFileshen"
          >立即上传</el-button
        >
        <el-button size="small" @click="quadd">取消</el-button>
      </div>
    </el-dialog>
    <!-- 身份证反面 -->
            <el-dialog title="添加图片" :visible.sync="shenfenzhengbei" width="30%">
      <div class="biaoge">
        <el-upload
          drag
          :multiple="true"
          :limit="1"
          :auto-upload="false"
          accept=".jpg, .jpeg, .png, .JPG, .JPEG, .PNG"
          :action="UploadUrl()"
          :on-change="fileChangeshenbei"
          :on-exceed="exceedFile"
          :on-success="handleSuccess"
          :on-error="handleError"
          :file-list="fileListshenbei"
          :before-remove="beforeRemoveshenbei"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将图片拖到此处，或
            <em>点击上传</em>
          </div>
          <div class="el-upload__tip" slot="tip">只能上传图片</div>
        </el-upload>
        <br />
        <el-button size="small" type="primary" @click="uploadFileshenbei"
          >立即上传</el-button
        >
        <el-button size="small" @click="quadd">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Qs from "qs";
import { color } from "echarts";
export default {
  data() {
    return {
      activeName: "second",
      shenfenzheng:false, //身份证
      shenfenzhengbei:false, //身份证反面
      fileListshen:[], //身份证的数组
      fileListshenbei:[], //身份证背面的数组
      activeNmae: "first", //选项卡
      dialogVisibleqx: false,
      isShow: true,
      someOne: {
        pagesize: 5,
        pagenum: 1,
      },
      someOned: {
        pagesized: 5,
        pagenumd: 1,
      },
      total: 0,
      totald: 0,
      photo: [],
      photod: [],
      tDialogVisible: false,
      edutDialogVisible: false,
      limit: 10,
      fileList: [], // excel文件列表
      file: "",
      // 控制合格证的显示与隐藏
      phoyodialogVisible: false,
      phoyodialog: 1,
      addNumberdialogVisible: false, //控制子账号添加的弹框

      //1的时候是合同支付上传  2的时候是营业执照上传
      value1: "",
      // 权限
      authority: {
        register: {
          // 登记状态
          registerQuery: false, //登记查询
          registerReport: false, //登记上报
        },
        subject: {
          subjectQuery: false, //主题信息查询
          subjectReport: false, //主体信息上报
        },
        focus: {
          focusMain: false, //关注主题信息
          focusCar: false, //关注车辆信息
        },

        industry: {
          industryReport: false, //行业主体上报
        },
        judge: {
          jurisdiction: false, //车型查询
        },
        carPrice: {},
        userId: "",
      },
      options: [
        {
          value: "金融机构",
          label: "金融机构",
        },
        {
          value: "主机厂",
          label: "主机厂",
        },
        {
          value: "物流企业",
          label: "物流企业",
        },
        {
          value: "政府机构",
          label: "政府机构",
        },
        {
          value: "经销商",
          label: "经销商",
        },
      ],
      options1: [
        {
          value: "V2",
          label: "V2",
        },
        {
          value: "V3",
          label: "V3",
        },
        {
          value: "V4",
          label: "V4",
        },
        {
          value: "V5",
          label: "V5",
        },
        {
          value: "V6",
          label: "V6",
        },
        {
          value: "V7",
          label: "V7",
        },
        {
          value: "V8",
          label: "V8",
        },
      ],
      value: "",
      gridData: [],
      companyDialogVisible: false, //点击公司 展示公司信息
      status: true, //判断正式会员
      //判断非正式会员
      emptyText: "请输入机构名称查询",
      file: "",
      dialogVisible: false,
      blurKey: "", //用户输入的
      dialogVisible: false, //控制对话框的显示隐藏
      tableData: [], //正式会员的参数
      tableDA: [], //非正式会员的参数
      multipleSelection: [],
      subjectId: "",
      userlist: "",
      member: "",
      addForm: {
        orgType: "", //类型
        orgName: "", //公司名称
        uscCode: "", //信用代码
        coin: "",
        money: "",
        memberLevel: "", //会员等级
        loginName: "", //管理账号
        phone: "", //手机号
        password: "", //密码
        time: "", //时间
        dealPay: [], //合同支付凭证
        contract:[],
        businessLicense: [], //营业执照上传
        idCardFront:[],//身份证正面
        idCardBehind:[],//身份证背面
        idCard:"",//身份证号码
        list: null, //下面展示的数据
      },
      getNums: "",
      tiJiaoOne: true,
    };
  },
  created() {
    this.onSubmit();
  },
  methods: {
    // 选项卡
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleSizeChange(newSize) {
      this.someOne.pagesize = newSize;
      this.onSubmit();
    }, // 监听pagenum的改变
    handleCurrentChange(newPage) {
      this.someOne.pagenum = newPage;
      this.onSubmit();
    },
    handleSizeChanged(newSize) {
      this.someOned.pagesized = newSize;
      this.onSubmit();
    }, // 监听pagenum的改变
    handleCurrentChanged(newPage) {
      this.someOned.pagenumd = newPage;
      this.onSubmit();
    },
    getNum(value) {
      this.getNums = value;
    },
    // 点击合同做一个显示

    editd() {
      console.log(222);
      console.log(this.photo);
      console, log(1212);
      this.edutDialogVisible = true;
    },
    editdd() {
      console.log(this.photo);
      this.tDialogVisible = true;
    },
    // 点击编辑做一个跳转
    edit() {
      (this.companyDialogVisible = false), (this.dialogVisible = true);
      console.log(this.userlist);
      this.addForm = this.userlist;
      if (this.addForm.orgName != "") {
        this.isShow = false;
      }
    },
      // 点击公司出来弹窗
    async conpany(e) {
      console.log(e);
      // console.log(e.memberLevel);
      // this.userlist = e;
      const { data: res } = await this.$http({
        method: "get",
        url: "cooperationMember/getMember/" + e.id,
      });
      if (res.code !== 200) return this.$message.error("状态改变失败");

      this.$message.success("状态改变成功");

      this.userlist = res.data;
      console.log(this.userlist)
      this.photo = eval(res.data.dealPay);
      this.photod = eval(res.data.businessLicense);
      console.log(this.photo);
      this.gridData = res.data.levelInfo;
  console.log(res.data.levelInfo)
      this.companyDialogVisible = true;
    },
    async memberLevelchang(e) {
      console.log(e);
      const { data: res } = await this.$http({
        method: "get",
        url: "cooperationMember/getMemberLevel?level=" + e,
      });
      if (res.code !== 200) return this.$message.error("状态改变失败");

      this.$message.success("状态改变成功");
      console.log(res.data);
      this.gridData = res.data;
       console.log(this.gridData)
      // console.log(1112121)
    },
    // 文件超出个数限制时的钩子
    exceedFile(files, fileList) {
      this.$message.warning(
        `只能选择1个图片，当前共选择了 ${
          files.length + fileList.length
        } 个`
      );
    },
    // 文件状态改变时的钩子
    fileChange(file, fileList) {
      this.fileList.push(file.raw);
    },
    fileChangeshen(file, fileList) {
      this.fileListshen.push(file.raw);
    },
    fileChangeshenbei(file, fileList) {
      this.fileListshenbei.push(file.raw);
    },
    beforeRemove(file, fileList) {
      this.fileList = fileList;
      console.log(this.fileList);
    },
    beforeRemoveshen(file, fileListshen) {
      this.fileListshen = fileListshen;
      console.log(this.fileListshen);
    },
    beforeRemoveshenbei(file, fileListshenbei) {
      this.fileListshenbei = fileListshenbei;
      console.log(this.fileListshenbei);
    },
    // 文件上传成功时的钩子
    handleSuccess(res, file, fileList) {
      this.$message.success("图片上传成功");
    },
    // 文件上传失败时的钩子
    handleError(err, file, fileList) {
      this.$message.error("图片上传失败");
    },
    UploadUrl: function () {
      // 因为action参数是必填项，我们使用二次确认进行文件上传时，直接填上传文件的url会因为没有参数导致api报404，所以这里将action设置为一个返回为空的方法就行，避免抛错
      return "";
    },
    uploadFile() {
      console.log(this.fileList);
      if (this.fileList.length === 0) {
        this.$message.warning("请上传图片");
      } else {
        const len = this.fileList.length;
        let num = 0;
        this.fileList.forEach((item) => {
          let form = new FormData();
          this.file = item;
          form.append("attachFile", this.file);
          this.$http({
            method: "post",
            url: "cooperationMember/attachFile",

            headers: {
              "Content-type": "multipart/form-data",
            },
            data: form,
          }).then(
            (res) => {
              if (res.data.code == 200) {
                num++;
                // this.$message.success("添加图片成功");
                // this.dialogVisible = false;
                if (num === len) {
                  this.$message.success("添加图片成功");
                  this.phoyodialogVisible = false;
                  this.fileList = [];
                }
              } else if (res.data.code == 701) {
                this.$message.success("图片格式不对，请上传正确的图片格式");
              } else {
                this.$message.error("添加图片失败");
              }
              console.log(this.phoyodialog);
              if (this.phoyodialog == 1) {
                this.addForm.dealPay.push({ url: res.data.data });
              }
                if(this.phoyodialog==2){
              this.addForm.businessLicense.push({ url: res.data.data });
              }
              if(this.phoyodialog==3){
                this.addForm.contract.push({url:res.data.data})
              }
              if(this.phoyodialog ==4){
                  this.addForm.idCardFront.push({ url: res.data.data });
              }
              if(this.phoyodialog==5){
                  this.addForm.idCardBehind.push({ url: res.data.data });
              }
            },
            (err) => {}
          );
        });

        console.log(this.file);
      }
    },

      uploadFileshen() {
      console.log(this.fileListshen);

      if (this.fileListshen.length === 0) {
        this.$message.warning("请上传图片");
      } else {
        const len = this.fileListshen.length;
        let num = 0;
        this.fileListshen.forEach((item) => {
          let form = new FormData();
          this.file = item;
          form.append("attachFile", this.file);
          this.$http({
            method: "post",
            url: "cooperationMember/attachFile",
            headers: {
              "Content-type": "multipart/form-data",
            },
            data: form,
          }).then(
            (res) => {
              if (res.data.code == 200) {
                num++;
                if (num === len) {
                  this.$message.success("添加图片成功");
                  this.phoyodialogVisible = false;
                  console.log(res.data.data)
                  // this.addForm.idCardFront = res.data.data
                    this.addForm.idCardFront.push({ url: res.data.data });
                  this.fileListshen = [];
                }
              } else if (res.data.code == 701) {
                this.$message.success("图片格式不对，请上传正确的图片格式");
              } else {
                this.$message.error("添加图片失败");
              }
              console.log(this.phoyodialog);
              if (this.phoyodialog == 1) {
                this.addForm.dealPay.push({ url: res.data.data });
              }
                    if(this.phoyodialog==2){
              this.addForm.businessLicense.push({ url: res.data.data });
              }
              if(this.phoyodialog==3){
                this.addForm.contract.push({url:res.data.data})
              }
            },
            (err) => {}
          );
        });

        console.log(this.file);
      }
    },
    // 身份证背面
          uploadFileshenbei() {
      console.log(this.fileListshenbei);
      if (this.fileListshenbei.length === 0) {
        this.$message.warning("请上传图片");
      } else {
        const len = this.fileListshenbei.length;
        let num = 0;
        this.fileListshenbei.forEach((item) => {
          let form = new FormData();
          this.file = item;
          form.append("attachFile", this.file);
          this.$http({
            method: "post",
            url: "cooperationMember/attachFile",
            headers: {
              "Content-type": "multipart/form-data",
            },
            data: form,
          }).then(
            (res) => {
              if (res.data.code == 200) {
                num++;
                if (num === len) {
                  this.$message.success("添加图片成功");
                  this.shenfenzhengbei = false;
                  console.log(res.data.data)
                  // this.addForm.idCardBehind = res.data.data
                     this.addForm.idCardBehind.push({ url: res.data.data });
                  this.fileListshenbei = [];
                }
              } else if (res.data.code == 701) {
                this.$message.success("图片格式不对，请上传正确的图片格式");
              } else {
                this.$message.error("添加图片失败");
              }
              console.log(this.phoyodialog);
              if (this.phoyodialog == 1) {
                this.addForm.dealPay.push({ url: res.data.data });
              }
                    if(this.phoyodialog==2){
              this.addForm.businessLicense.push({ url: res.data.data });
              }
              if(this.phoyodialog ==3){
                 this.addForm.contract.push({ url: res.data.data });
              }
            },
            (err) => {}
          );
        });

        console.log(this.file);
      }
    },
    quadd() {
      this.phoyodialogVisible = false;
    },
    Photoad() {
      this.phoyodialog = 2;
      this.phoyodialogVisible = true;
    },
    // 点击合格证上传
    Photoadd() {
      this.phoyodialog = 1;
      this.phoyodialogVisible = true;
    },
    Photoaddd(){
      this.phoyodialog =3;
      this.phoyodialogVisible =true;
    },
       Photoadddd(){
      this.phoyodialog =4;
      this.phoyodialogVisible =true;
    },
       Photoaddddd(){
      this.phoyodialog =5;
      this.phoyodialogVisible =true;
    },


    // 点击来回切换
    sort() {
      //根据你的downIcon判断此时的排序是升序还是降序
      //...排序方式
      this.status = true;
      console.log(11111);
    },
    sortd() {
      //根据你的downIcon判断此时的排序是升序还是降序
      //...排序方式
      this.status = false;
      console.log(222);
    },
    quxiao() {
      this.dialogVisible = probation;
    },

    // // 新增会员
    piliangcha() {
      this.addForm = {
        orgType: "", //类型
        orgName: "", //公司名称
        uscCode: "", //信用代码
        coin: "",
        money: "",
        memberLevel: "", //会员等级
        loginName: "", //管理账号
        phone: "", //手机号
        password: "", //密码
        time: "", //时间
        dealPay: [], //合同支付凭证
        contract:[],
        businessLicense: [], //营业执照上传
        list: null,
      };

      console.log(this.addForm.orgName);
      if (this.addForm.orgName == "") {
        this.isShow = true;
        console.log("可以填写");
      }
      this.dialogVisible = true;
    },
    // 滑块开始结束会员
    async statusRight(mess) {
      let stu = null;
      if (mess.status == false) {
        console.log("0");
        stu = 0;
      } else {
        console.log("1");
        stu = 1;
      }
      const { data: res } = await this.$http.get(
        `/cooperationMember/setMember/${mess.orgName}/${stu}`
      );
      console.log(res);
      if (res.code != 200) return this.$message.error("修改错误");
      this.$message.success("修改成功");
    },
    deleteListOne(id) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data: res } = await this.$http.delete(
            `/cooperationMember/delMember/${id.orgName}`
          );
          console.log(res);
          if (res.code != 200) return this.message.error("删除失败");
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.onSubmit();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 升级正式会员
    indexedit(e) {
      console.log(e);
      this.dialogVisible = true;
    },
      // input失去焦点时间
    async onChangeName() {
      console.log("失去焦点了");
      if (this.addForm.orgName == ""){
         this.$message.error("不能为空");
        this.tiJiaoOne = false;
        return
      }
      const { data: res } = await this.$http.get(
        `/cooperationMember/isCompanyName/${this.addForm.orgName}`
        // `/cooperationMember/isPhone/${this.addForm.phone}`
      );
      console.log(res);
      if (res.code == 708) {
        this.$message.error("主体名称已存在");
        this.tiJiaoOne = false;
        return;
      } else {
        this.$message.success("主体名称可以使用");
        this.tiJiaoOne = true;
      }
    },
    // input失去焦点时间
    async onChange() {
      console.log("失去焦点了");
      if (this.addForm.phone == ""){
         this.$message.error("手机号不能为空");
        this.tiJiaoOne = false;
        return
      }

      if (!/^1[34578]\d{9}$/.test(this.addForm.phone)) {
         this.$message.error("请填写正确的手机号");
        this.tiJiaoOne = false;
        return

      }
      const { data: res } = await this.$http.get(
        `/cooperationMember/isPhone/${this.addForm.phone}`
      );
      console.log(res);
      if (res.code == 707) {
        this.$message.error("手机号已经绑定主体");
        this.tiJiaoOne = false;
        return;
      } else {
        this.$message.success("手机号可以使用");
        this.tiJiaoOne = true;
      }
    },
    // 点击开通会员提交
    async tijiao() {
      if (!this.tiJiaoOne) {
        return this.$message.error("手机号有误");
      }
      this.addForm.list = this.gridData;
      console.log(this.addForm);
      let num = 0;
      let gum = 0;
      if (
        this.addForm.orgType == "" ||
        this.addForm.orgName == "" ||
        this.addForm.loginName == "" ||
        this.addForm.coin == "" ||
        this.addForm.money == "" ||
        this.addForm.phone == "" ||
        this.addForm.list == [] ||
        this.addForm.dealPay == [] ||
        this.addForm.time == [] ||
        this.addForm.memberLevel == ""
      ) {
        return this.$message.error("请补充信息");
      }
      this.addForm.list.forEach((item) => {
        if (item.countMonth == "") {
          num++;
        }
        var numReg = /^[0-9]*$/;
        var numRe = new RegExp(numReg);
        if (!numRe.test(item.countMonth)) {
          console.log("不能发送");
          gum++;
        }
      });
      if (num != 0) return this.$message.error("请补充信息");
      if (gum != 0) return this.$message.error("请补充信息");

      const { data: res } = await this.$http({
        method: "post",
        url: "cooperationMember/addCooperationMember",
        data: this.addForm,
      });
      if (res.code !== 200) return this.$message.error("开通账号失败");
      this.$message.success("开通账号成功");
      this.addForm = {};
       (this.addForm.idCardFront = []),
        (this.addForm.idCardBehind = []),
       (this.addForm.contract=[]),
      (this.addForm.dealPay = []),
        (this.addForm.businessLicense = []),
        (this.dialogVisible = false);
    },
    // 点击查询信息
    async onSubmit(e) {
      // (this.tableData = []), (this.tableDA = []),
      console.log(this.status);
      if (this.status == true) {
        this.member = "approved";
        // 正式会员
        const { data: res } = await this.$http.get(
          "cooperationMember/getCooperationMemberList",
          {
            params: {
              blurKey: this.blurKey,
              status: this.member,
              limit: this.someOne.pagesize,
              offset: this.someOne.pagenum,
            },
          }
        );
        if (res.code !== 200) return this.$message.error("查询失败");
        if (res.data.total == 0) {
          this.emptyText = "无信息";
          return this.$message("无信息");
        }
        this.$message.success("查询成功");
        res.data.list.forEach((item) => {
          if (item.status == "1") {
            item.status = true;
          } else {
            item.status = false;
          }
        });
        this.total = res.data.total;
        this.tableData = res.data.list;
        console.log(this.tableData);
      } else {
        this.member = "probation";
        console.log(2233232);
        const { data: res } = await this.$http.get(
          "cooperationMember/getCooperationMemberList",
          {
            params: {
              blurKey: this.blurKey,
              status: this.member,
              limit: this.someOned.pagesized,
              offset: this.someOned.pagenumd,
            },
          }
        );
        if (res.code !== 200) return this.$message.error("查询失败");
        if (res.data.total == 0) {
          this.emptyText = "无信息";
          return this.$message("无信息");
        }
        this.$message.success("查询成功");
        this.totald = res.data.total;
        this.tableDA = res.data.list;
        console.log(this.tableDA);
      }
    },
    onshen(){
      this.shenfenzheng = true;
    },
        onshenbei(){
      this.shenfenzhengbei = true;
    }
  },
};
</script>

<style lang="less" scoped>
.getSubject {
  .header {
    margin-top: 10px;
    width: 100%;
    .headerImg {
      width: 100%;
    }
  }

  .dia {
    .a_diaa_dia {
      display: flex;
      align-items: center;
      margin-top: 10px;
      justify-content: center;
      .first_dia {
        width: 128px;
        height: 22px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #333333;
        opacity: 1;
        text-align: right;
      }
      .second_dia {
        width: 234px;
        height: 34px;
        background: #f4f7fe;
        border: 1px solid #cccccc;
        opacity: 1;
        border-radius: 6px;
        margin-left: 30px;
        box-sizing: border-box;
        padding-left: 14px;
      }
      .second_dia:focus {
        outline: none;
        border: 1px solid #0083e3;
      }
    }
  }
  .addhui {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .second-addhui {
      display: flex;
      .first {
        width: 124px;
        height: 42px;
        border: 1px solid #707070;
        opacity: 1;
        border-radius: 100px 0px 0px 100px;
        line-height: 42px;
        text-align: center;
      }
      .second {
        width: 124px;
        height: 42px;

        opacity: 1;
        border: 1px solid #707070;
        border-radius: 0px 100px 100px 0px;
        line-height: 42px;
        text-align: center;
      }
      .addcolor {
        background: #1883e3;

        color: #ffffff;
      }
    }
  }
  /deep/.el-dialog__header {
    background-color: #1883e3;
  }
  /deep/.el-dialog__title {
    width: 64px;
    height: 22px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: #ffffff;
    opacity: 1;
  }
  .compangdia {
    display: flex;
    margin-top: 20px;
    .first_compangdia {
      width: 80px;
      height: 22px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 22px;
      color: #999999;
      opacity: 1;
      text-align: right;
    }
    .second_compangdia {
      height: 22px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 22px;
      color: #333333;
      opacity: 1;
      margin-left: 30px;
    }
    .three_compangdia {
      width: 83px;
      height: 23px;
      border: 1px solid #0083e3;
      opacity: 1;
      border-radius: 6px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 23px;
      color: #0083e3;
      opacity: 1;
      text-align: center;
      margin-left: 10px;
    }
  }
  .chanpin {
    width: 56px;
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 20px;
    color: #333333;
    opacity: 1;
    margin-top: 10px;
  }
  .ender {
    display: flex;
    align-items: center;
    // justify-content: space-around;
    justify-content: center;
    .endera {
      width: 122px;
      height: 25px;
      border: 1px solid #0083e3;
      opacity: 1;
      border-radius: 6px;
      text-align: center;
      line-height: 25px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: bold;
      margin-left: 15px;
      color: #0083e3;
      opacity: 1;
      margin-top: 18px;
    }
  }
  .tijiao {
    width: 115px;
    height: 34px;
    background: #0083e3;
    opacity: 1;
    border-radius: 6px;
    text-align: center;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 34px;
    color: #ffffff;
    opacity: 1;
    margin: 20px auto;
    cursor: pointer;
  }
  // /deep/.el-input--suffix .el-input__inner {
  //   padding-right: 30px;
  //   margin-left: 30px;
  //   width: 240px;
  //   background: #f4f7fe;
  //   border: 1px solid #cccccc;
  //   opacity: 1;
  //   border-radius: 6px 0px 0px 6px;
  // }
  /deep/.el-range-editor.el-input__inner {
    display: inline-flex;
    align-items: center;
    /* padding: 3px 10px; */
    width: 234px;
    height: 34px;
    background: #f4f7fe;
    border: 1px solid #cccccc;
    opacity: 1;
    border-radius: 6px;
    margin-left: 30px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    /* line-height: 17px; */
    color: #cccccc;
    opacity: 1;
  }
}
/deep/.el-dialog__headerbtn .el-dialog__close {
  color: #cccc;
}
.data_first_dia {
  margin-left: 30px;
  width: 240px;
  background: #f4f7fe;
  border: 0px solid #cccccc;
  opacity: 1;
  border-radius: 6px 0px 0px 6px;
}
// tabs
// 选项卡样式
/deep/.el-tabs__nav-scroll {
  text-align: center;
  margin-left: 190px;
  border-bottom: none;
}
// /deep/[data-v-238829a5] .el-tabs--card > .el-tabs__header .el-tabs__nav {

//     background-color: blue;
// }
// /deep/.el-tabs__item is-top{
//   border-top-left-radius: 50px;
// }
/deep/.el-tabs--card > .el-tabs__header .el-tabs__nav {
  // border: 1px solid #E4E7ED;
  border: 1px solid #707070;
  //  border-bottom: none;
  // border-radius: 4px 4px 0 0;
  /* box-sizing: border-box; */
  border-radius: 25px;
  width: 200px;
  height: 42px;
  opacity: 1;
  //  border-radius: 100px 0px 0px 100px;
}
/deep/.el-tabs--card > .el-tabs__header {
  // border-bottom: 1px solid #E4E7ED;
  border-bottom: 1px solid #ffffff;
  // border-bottom: none;
}
// /deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
//     border-bottom:none;
// }
.quanxian {
  width: 80px;
  height: 28px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 28px;
  color: #333333;
  opacity: 1;
  margin-top: 20px;
  margin-bottom: 10px;
}
.dengji {
  width: 100%;
  height: 96px;
  .text {
    width: 100px;
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 20px;
    color: #666666;
    opacity: 1;
    margin-bottom: 10px;
  }
  /deep/.el-switch__label--left {
    margin-right: 48px;
  }
  /deep/.dengjiSecond {
    margin-left: 50px;
  }
}
.zhuti {
  margin-top: 5px;
}
.tiSure {
  margin-top: 20px;
  width: 100%;
}

// /deep/.getSubject[data-v-238829a5] .el-dialog__header {
//    background-color: #ffffff;
//    padding: 20px 20px 10px;
// }
/deep/.el-dialog__header {
  padding: 20px 20px 10px;
  background-color: #ffffff !important;
}
</style>
